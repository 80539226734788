export default [
	require("./new").default,
	require("./example-node").default,
	require("./animation-frame").default,
	require("./audio-device").default,
	require("./button").default,
	require("./canvas-color-stats").default,
	require("./canvas").default,
	require("./conditional-send").default,
	require("./conditional-value").default,
	require("./css-rule").default,
	require("./css-background").default,
	require("./css-filter-element").default,
	require("./css-filter").default,
	require("./css-transform-element").default,
	require("./css-transform").default,
	require("./delay-value").default,
	require("./delay").default,
	require("./difference").default,
	require("./flip-flop").default,
	require("./function3").default,
	require("./gamepad").default,
	require("./iframe").default,
	require("./increment").default,
	require("./lazer-grid").default,
	require("./min").default,
	require("./max").default,
	require("./abs").default,
	require("./invert").default,
	require("./midi-device").default,
	require("./midi-note-on").default,
	require("./draw-notes").default,
	require("./mouse").default,
	require("./note").default,
	require("./pixel-canvas").default,
	require("./pulse").default,
	require("./random-number").default,
	require("./send").default,
	require("./three-js-two-cubes").default,
	require("./three-js").default,
	require("./tr-8s-cc-map").default,
	require("./tr-8s-drum-map").default,
	require("./tween").default,
	require("./value-boolean").default,
	require("./value-number").default,
	require("./value-string").default,
	require("./value-color").default,
	require("./video-device").default,
	require("./video").default
];
